<template>
  <div>
    <nav-bar
      v-if="loginType === LoginTypeEnum.TASK_COLLECT"
      @onBack="handleBack"
    />
    <excelUpload :login-type="LoginTypeEnum.TASK_ALLOCATE_ASSIGN"></excelUpload>
  </div>
</template>

<script>
import NavBar from '../components/nav-bar.vue'
import excelUpload from '../components/excel-upload.vue'
import { LoginTypeEnum } from '@/apis/config';
import backListMixins from '../mixins/back-list'
import { getTokenToObj } from '@/tools/utils'

export default {
  name: 'UserRegister',
  components: {
    excelUpload,
    NavBar
  },
  mixins: [backListMixins],
  created(){
    const { loginType } = getTokenToObj() || {};
    this.loginType = +loginType;
  },
  data() {
    return {
      LoginTypeEnum,
      loginType: LoginTypeEnum.USER_REGISTER_ASSIGN
    }
  },
}
</script>